/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { Application } from '@clients/adventure/bean/application';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { PaginedResponse } from '@core/dtos/pagined-response.dto';
import {
  FurtherWaypointsResponse,
  ReachabilityWaypointsResponse,
  RequiredWaypointsResponse
} from '@core/dtos/waypoint.dto';
import { ApplicationContext, WaypointClient, WaypointConcept } from '@core/services';
import { Company } from '@models/company/company';
import { Filters } from '@models/filters/filters';
import { Gender } from '@models/gender/gender';
import { ItineraryGroup } from '@models/itinerary-group/itinerary-group';
import { HumanPoi } from '@models/poi/human-poi/human-poi';
import { School } from '@models/school/school';
import { Structure } from '@models/structure/structure';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { Regions } from '@organisms/localisation-filter-dialog/regions';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WaypointService {
  constructor(private client: WaypointClient, public ctx: ApplicationContext) {}

  /**
   * Get the icon of a waypoint according its type
   *
   * @param waypoint The waypoint to get the icon
   * @returns
   */
  public getIcon(waypoint: Waypoint): string {
    switch (waypoint.type) {
      case WaypointType.company:
        return '🏢';
      case WaypointType.school:
        return '🏫';
      case WaypointType.diploma:
        return '🎓';
      case WaypointType.job:
        return '💼';
      default:
        return '';
    }
  }

  /**
   * Get a waypoint from an id
   *
   * @param waypointId The waypoint id
   * @param gender The user gender
   * @param regions The region used for trends
   * @param applicationNodeId
   * @returns The matching waypoint
   */
  public getWaypoint(
    waypointId: string,
    gender: Gender,
    regions?: Regions,
    applicationNodeId?: string
  ): Observable<Waypoint> {
    return this.client.waypoint(waypointId, gender, regions, applicationNodeId);
  }

  /* -- SCHOOL -- */

  /**
   * Retrieve schools associated to a given waypoint within a given application, optionally using
   * filters
   *
   * @param waypoint The waypoint
   * @param application The application
   * @param filters Optional filters
   * @param limit The number of results allowed, default 100
   * @returns The matching schools
   */
  public searchSchools(
    waypoint: Waypoint,
    application: Application,
    filters?: Filters
  ): Observable<PaginedResponse<School>> {
    filters = filters || new Filters();

    filters.limit = filters.limit || 100;

    return this.client.searchSchools(waypoint.id, application.nodeId, waypoint.type, filters);
  }

  /**
   * Retrieve a school associated to a given waypoint
   *
   * @param waypoint The waypoint
   * @param application The application
   * @param schoolId The school Id
   * @returns The matching school
   */
  public getSchool(
    waypoint: Waypoint,
    application: Application,
    schoolId: string
  ): Observable<School> {
    return this.client.getSchool(waypoint.id, application.nodeId, schoolId);
  }

  /* -- STRUCTURE -- */

  /**
   * Retrieve structure within a given application, optionally using
   * filters
   *
   * @param applicationNodeId The application node id
   * @param structureFilters Optional filters
   * @param limit The number of results allowed, default 100
   * @returns The matching structures
   */
  public structures(
    applicationNodeId: string,
    structureFilters?: Filters,
    limit: number = 100
  ): Observable<Structure[]> {
    return this.client.structures(applicationNodeId, structureFilters, limit);
  }

  /* -- ORIGINS -- */

  /**
   * Get origin waypoints for a destination and a user gender
   *
   * @param destinationId The destination Id
   * @param originFilters Optional filters
   */
  public getOrigins(destinationId: string, originFilters?: Filters): Observable<Waypoint[]> {
    return this.client.getOrigins(destinationId, originFilters);
  }

  /* -- RESUME NODE -- */

  /**
   * Find resume nodes (jobs or diplomas) matching a given search text based on the user status
   *
   * @param applicationNodeId The application node Id
   * @param resumeNodeType The resume node type (or waypoint type). Can be Job or Diploma
   * @param searchText The search text
   * @param filters The filters containing the region used to get the job trends
   * @returns The waypoint results
   */
  public searchResumeNodes(
    applicationNodeId: string,
    resumeNodeType: WaypointType,
    searchText?: string,
    filters?: Filters
  ): Observable<Waypoint[]> {
    return this.client.searchResumeNodes(applicationNodeId, resumeNodeType, searchText, filters);
  }

  /**
   * Find resume nodes (jobs or diplomas) matching a given search text based on the user status
   *
   * @param applicationNodeId The application node Id
   * @param searchText The search text
   * @param companyFilters The company search filters
   * @returns The waypoint results
   */
  public searchCompaniesByText(
    applicationNodeId: string,
    searchText: string,
    companyFilters: Filters
  ): Observable<Company[]> {
    return this.client.searchCompaniesByText(applicationNodeId, searchText, companyFilters);
  }

  /* -- COMPANY -- */

  /**
   * Retrieves companies associated to a given waypoint within a given application, optionally using
   * filters
   *
   * @param waypoint The waypoint
   * @param application The application
   * @param filters Optional filters
   * @returns The matching companies
   */
  public searchCompanies(
    waypoint: Waypoint,
    application: Application,
    filters?: Filters
  ): Observable<PaginedResponse<Company>> {
    filters = filters || new Filters();

    filters.limit = filters.limit || 100;

    return this.client.searchCompanies(waypoint.id, application.nodeId, waypoint.type, filters);
  }

  /* -- ITINERARIES -- */

  /**
   * Get itineraries for a destination
   *
   * @param destinationId The destination Id
   * @param itinerariesFilters Optional filters
   * @returns An observable of the itineraries matching the destination and the filters
   */
  public itineraries(
    destinationId: string,
    itinerariesFilters: Filters
  ): Observable<ItineraryGroup[]> {
    return this.client.itineraries(destinationId, itinerariesFilters);
  }

  /* -- HUMAN -- */

  /**
   * Get Humans POIs for a waypoint
   *
   * @param waypointId The waypoint ID
   * @returns An array of HumanPois for this waypoint
   */
  public humanPois(waypointId: string, humansFilters?: Filters): Observable<HumanPoi[]> {
    return this.client.humanPois(waypointId, humansFilters);
  }

  public getWaypointConcept(
    waypointId: string,
    nomenclature: WaypointType
  ): Observable<WaypointConcept> {
    return this.client.getWaypointConcept(waypointId, nomenclature);
  }

  /* -- FUTHER -- */

  /**
   * Get further waypoints
   *
   * @param waypointId The origin waypoint ID
   * @returns The diplomas and jobs to go further after this waypoint
   */
  public getFurther(waypointId: string): Observable<FurtherWaypointsResponse> {
    return this.client.getFurther(waypointId);
  }

  /* -- REACHABILITY -- */

  /**
   * Get the reachability between to waypoints
   *
   * @param fromUUid The departure waypoint uuid
   * @param toUUid The destination waypoint uuid
   * @returns The reachability between these two waypoints
   */
  public getReachability(
    fromUuid: string,
    toUuuid: string
  ): Observable<ReachabilityWaypointsResponse> {
    return this.client.getReachability(fromUuid, toUuuid);
  }

  /**
   * Get required diplomas to have bjust before the waypoint
   *
   * @param toUUid The destination waypoint uuid
   * @returns A list of diplomas
   */
  public getRequiredDiplomas(
    toUuuid: string,
    appUuid: string
  ): Observable<RequiredWaypointsResponse> {
    return this.client.getRequiredDiplomas(toUuuid, appUuid);
  }
}
